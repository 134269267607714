import React from "react"
import { Helmet } from "react-helmet"

import Layout from "../components/layout/layout"
import Scroll from "../components/scroll/scroll"
import Hero from "../components/hero/hero"
import About from "../components/about/about"
import Projects from "../components/projects/projects"
import Contact from "../components/contact/contact"
import Head from "../components/head"

const IndexPage = () => {
  return (
    <Layout>
      <Helmet>
        <meta
          name="description"
          content="I am a full-stack developer specialising in building web applications with JavaScript and React. My goal is to deliver quality creative solutions across digital platforms and beyond. I enjoy working with others on exciting projects, learning new web technologies and keeping up to date with the latest trends & developments within the tech industry."
        />
      </Helmet>
      <Head title="Home" />
      <Scroll showBelow={250} />
      <Hero />
      <About />
      <Projects />
      <Contact />
    </Layout>
  )
}

export default IndexPage
