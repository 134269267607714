import React from "react"
import { Link } from "gatsby"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import scrollTo from "gatsby-plugin-smoothscroll"
import Fade from "react-reveal/Fade"

import heroStyles from "../hero/hero.module.scss"

const Hero = () => {
  const data = useStaticQuery(graphql`
    query {
      mRob: file(relativePath: { eq: "mo-rob-2022.png" }) {
        childImageSharp {
          fluid(maxWidth: 300, quality: 90) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <section className={heroStyles.hero}>
      <div className={heroStyles.hero__container}>
        <Fade top>
          <div className={heroStyles.hero__InfoContainer}>
            <h1 className={heroStyles.hero__title}>
              Heyo! I'm Mo.<br></br>A full-stack developer from Manchester.
            </h1>
            <h3
              className={heroStyles.hero__cta}
              onClick={() => scrollTo("#projects")}
            >
              <Link className={heroStyles.hero__CtaLink}>
                Check out my work
              </Link>
            </h3>
          </div>
        </Fade>
        <div className={heroStyles.hero__ImgContainer}>
          <Img
            className={heroStyles.hero__img}
            fluid={data.mRob.childImageSharp.fluid}
            alt="Portrait of Mo Rob"
          />
        </div>
      </div>
    </section>
  )
}

export default Hero
