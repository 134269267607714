import React from "react"
import Fade from "react-reveal/Fade"

import aboutStyles from "../about/about.module.scss"

const About = () => {
  return (
    <section id="about" className={aboutStyles.about}>
      <div className={aboutStyles.about__container}>
        <div className={aboutStyles.about__colOne}>
          <Fade bottom>
            <h4 className={aboutStyles.colOneTitle}>Tools I use</h4>
            <ul className={aboutStyles.colOneList}>
              <li className={aboutStyles.colOneListItem}>HTML/CSS</li>
              <li className={aboutStyles.colOneListItem}>JavaScript/React</li>
              <li className={aboutStyles.colOneListItem}>GatsbyJS</li>
              <li className={aboutStyles.colOneListItem}>MongoDB</li>
              <li className={aboutStyles.colOneListItem}>Node.js</li>
              <li className={aboutStyles.colOneListItem}>Express</li>
              <li className={aboutStyles.colOneListItem}>Git/GitHub</li>
            </ul>
          </Fade>
        </div>
        <div className={aboutStyles.about__colTwo}>
          <Fade bottom>
            <h4 className={aboutStyles.colTwoTitle}>About me</h4>
            <p className={aboutStyles.colTwoDesc}>
              I am a full-stack developer specialising in building web
              applications with JavaScript and React. My goal is to deliver
              quality creative solutions across digital platforms and beyond. I
              enjoy working with others on exciting projects, learning new web
              technologies and keeping up to date with the latest trends &
              developments within the tech industry.
            </p>
            <p className={aboutStyles.colTwoDescGrey}>
              I have several years of experience working as a developer, both as
              a freelancer and for digital agencies developing websites for
              various clients. I also like to spend my free time sharpening my
              programming skills by building applications using React, which you
              can find below.
            </p>
          </Fade>
        </div>
      </div>
    </section>
  )
}

export default About
