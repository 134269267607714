import React from "react"
import { Link } from "gatsby"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import Fade from "react-reveal/Fade"

import projectsStyles from "../projects/projects.module.scss"

const Projects = () => {
  const data = useStaticQuery(graphql`
    query {
      weatherappScreenshot: file(
        relativePath: { eq: "weather-app-screenshot.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1920, quality: 90) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      readyrecipesScreenshot: file(
        relativePath: { eq: "ready-recipes-screenshot.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1920, quality: 90) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      carTraderAppScreenshot: file(
        relativePath: { eq: "car-trader-app-screenshot.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1920, quality: 90) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      invoiceTrackerAppScreenshot: file(
        relativePath: { eq: "invoice-tracker-app-screenshot.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1920, quality: 90) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      fyloScreenshot: file(
        relativePath: { eq: "fylo-dark-theme-landing-page-screenshot.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1920, quality: 90) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      worldinfoappScreenshot: file(
        relativePath: { eq: "world-info-app-screenshot.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1920, quality: 90) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      eshopScreenshot: file(relativePath: { eq: "e-shop-screenshot.png" }) {
        childImageSharp {
          fluid(maxWidth: 1920, quality: 90) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      travelgramScreenshot: file(
        relativePath: { eq: "travelgram-app-screenshot.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1920, quality: 90) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <section id="projects" className={projectsStyles.projects}>
      <div className={projectsStyles.projects__container}>
        <h4 className={projectsStyles.projects__title}>Projects</h4>
        <div className={projectsStyles.projects__cards}>
          <Fade>
            <div className={projectsStyles.card}>
              <Img
                className={projectsStyles.card__img}
                fluid={data.carTraderAppScreenshot.childImageSharp.fluid}
                alt="Car Trader App Screenshot"
              />
              <div className={projectsStyles.card__textContainer}>
                <h3 className={projectsStyles.card__title}>Car Trader</h3>
                <div className={projectsStyles.card__categoryContainer}>
                  <h4 className={projectsStyles.card__category}>React</h4>
                  <h4 className={projectsStyles.card__category}>MongoDB</h4>
                  <h4 className={projectsStyles.card__category}>Express</h4>
                  <h4 className={projectsStyles.card__category}>Node</h4>
                  <h4 className={projectsStyles.card__category}>Tailwind</h4>
                </div>
                <p className={projectsStyles.card__desc}>
                  A car trader app built using the MERN stack with various CRUD
                  operations that allow users to view the listing page, filter
                  cars by different criteria, add a car by creating an account
                  and delete or update the status of the added car from the
                  profile page. The cars and user credentials are stored in
                  MongoDB.
                </p>
                <div className={projectsStyles.card__linkContainer}>
                  <h4 className={projectsStyles.card__linkText}>
                    <Link
                      className={projectsStyles.card__link}
                      to="https://github.com/mo-rob-1/car-trader-app"
                    >
                      View Code
                    </Link>
                  </h4>
                  <h4 className={projectsStyles.card__linkText}>
                    <Link
                      className={projectsStyles.card__link}
                      to="https://car-trader-app-mocodes.herokuapp.com/"
                    >
                      View Project
                    </Link>
                  </h4>
                </div>
              </div>
            </div>
          </Fade>

          <Fade>
            <div
              className={`${projectsStyles.card} ${projectsStyles.card__marginTop}`}
            >
              <Img
                className={projectsStyles.card__img}
                fluid={data.travelgramScreenshot.childImageSharp.fluid}
                alt="travelGram App Screenshot"
              />
              <div className={projectsStyles.card__textContainer}>
                <h3 className={projectsStyles.card__title}>travelGram</h3>
                <div className={projectsStyles.card__categoryContainer}>
                  <h4 className={projectsStyles.card__category}>React</h4>
                  <h4 className={projectsStyles.card__category}>MongoDB</h4>
                  <h4 className={projectsStyles.card__category}>Express</h4>
                  <h4 className={projectsStyles.card__category}>Node</h4>
                  <h4 className={projectsStyles.card__category}>
                    Styled Components
                  </h4>
                </div>
                <p className={projectsStyles.card__desc}>
                  Built using the MERN stack, travelGram is an app that allows
                  you to upload and share your travel photos. You can view
                  images uploaded by other users and also share their profile.
                  travelGram is also responsive and users can view the app and
                  upload images from different sized devices.
                </p>
                <div className={projectsStyles.card__linkContainer}>
                  <h4 className={projectsStyles.card__linkText}>
                    <Link
                      className={projectsStyles.card__link}
                      to="https://github.com/mo-rob-1/travelgram-app"
                    >
                      View Code
                    </Link>
                  </h4>
                  <h4 className={projectsStyles.card__linkText}>
                    <Link
                      className={projectsStyles.card__link}
                      to="https://travelgram-app-mocodes.herokuapp.com/"
                    >
                      View Project
                    </Link>
                  </h4>
                </div>
              </div>
            </div>
          </Fade>

          <Fade>
            <div className={projectsStyles.card}>
              <Img
                className={projectsStyles.card__img}
                fluid={data.eshopScreenshot.childImageSharp.fluid}
                alt="e-Shop Screenshot"
              />
              <div className={projectsStyles.card__textContainer}>
                <h3 className={projectsStyles.card__title}>e-Shop</h3>
                <div className={projectsStyles.card__categoryContainer}>
                  <h4 className={projectsStyles.card__category}>React</h4>
                  <h4 className={projectsStyles.card__category}>Redux</h4>
                  <h4 className={projectsStyles.card__category}>MongoDB</h4>
                  <h4 className={projectsStyles.card__category}>Express</h4>
                  <h4 className={projectsStyles.card__category}>Node</h4>
                  <h4 className={projectsStyles.card__category}>Tailwind</h4>
                  <h4 className={projectsStyles.card__category}>Stripe API</h4>
                </div>
                <p className={projectsStyles.card__desc}>
                  An eCommerce site built using the MERN stack. Users can view
                  the products listing page, add products to the cart, select
                  product quantity, view the cart page, delete items from the
                  cart and make a test payment which was set up using the Stipe
                  API. Redux was used for state management and Tailwind was used
                  to style the site.
                </p>
                <div className={projectsStyles.card__linkContainer}>
                  <h4 className={projectsStyles.card__linkText}>
                    <Link
                      className={projectsStyles.card__link}
                      to="https://github.com/mo-rob-1/e-shop-app"
                    >
                      View Code
                    </Link>
                  </h4>
                  <h4 className={projectsStyles.card__linkText}>
                    <Link
                      className={projectsStyles.card__link}
                      to="https://e-shop-mocodes.herokuapp.com/"
                    >
                      View Project
                    </Link>
                  </h4>
                </div>
              </div>
            </div>
          </Fade>

          <Fade>
            <div
              className={`${projectsStyles.card} ${projectsStyles.card__marginTop}`}
            >
              <Img
                className={projectsStyles.card__img}
                fluid={data.invoiceTrackerAppScreenshot.childImageSharp.fluid}
                alt="Invoice Tracker App Screenshot"
              />
              <div className={projectsStyles.card__textContainer}>
                <h3 className={projectsStyles.card__title}>Invoice Tracker</h3>
                <div className={projectsStyles.card__categoryContainer}>
                  <h4 className={projectsStyles.card__category}>React</h4>
                  <h4 className={projectsStyles.card__category}>MongoDB</h4>
                  <h4 className={projectsStyles.card__category}>Express</h4>
                  <h4 className={projectsStyles.card__category}>Node</h4>
                </div>
                <p className={projectsStyles.card__desc}>
                  This app enables users to create, track and manage their
                  invoices by logging in or creating an account. User account
                  details and invoices are stored in a database using MongoDB.
                  Express was used to build the backend and React along with
                  Redux was used to develop the frontend functionality for user
                  authentication and invoice management.
                </p>
                <div className={projectsStyles.card__linkContainer}>
                  <h4 className={projectsStyles.card__linkText}>
                    <Link
                      className={projectsStyles.card__link}
                      to="https://github.com/mo-rob-1/invoice-tracker-app"
                    >
                      View Code
                    </Link>
                  </h4>
                  <h4 className={projectsStyles.card__linkText}>
                    <Link
                      className={projectsStyles.card__link}
                      to="https://invoice-tracker-app-mocodes.herokuapp.com/"
                    >
                      View Project
                    </Link>
                  </h4>
                </div>
              </div>
            </div>
          </Fade>

          <Fade>
            <div className={projectsStyles.card}>
              <Img
                className={projectsStyles.card__img}
                fluid={data.readyrecipesScreenshot.childImageSharp.fluid}
                alt="Ready Recipes Screenshot"
              />
              <div className={projectsStyles.card__textContainer}>
                <h3 className={projectsStyles.card__title}>Ready Recipes</h3>
                <div className={projectsStyles.card__categoryContainer}>
                  <h4 className={projectsStyles.card__category}>GatsbyJS</h4>
                  <h4 className={projectsStyles.card__category}>WordPress</h4>
                  <h4 className={projectsStyles.card__category}>
                    Styled Components
                  </h4>
                </div>
                <p className={projectsStyles.card__desc}>
                  A food recipes site developed using GatsbyJS and WordPress as
                  a headless CMS. GraphQL was used to query and render the
                  WordPress data onto the frontend of the site. A blog section
                  was developed and a custom post type was created to allow
                  logged-in users to add their own recipes. Styled Components
                  was used to style the site.
                </p>
                <div className={projectsStyles.card__linkContainer}>
                  <h4 className={projectsStyles.card__linkText}>
                    <Link
                      className={projectsStyles.card__link}
                      to="https://github.com/mo-rob-1/ready-recipes"
                    >
                      View Code
                    </Link>
                  </h4>
                  <h4 className={projectsStyles.card__linkText}>
                    <Link
                      className={projectsStyles.card__link}
                      to="https://readyrecipes.gatsbyjs.io/"
                    >
                      View Project
                    </Link>
                  </h4>
                </div>
              </div>
            </div>
          </Fade>

          <Fade>
            <div
              className={`${projectsStyles.card} ${projectsStyles.card__marginTop}`}
            >
              <Img
                className={projectsStyles.card__img}
                fluid={data.worldinfoappScreenshot.childImageSharp.fluid}
                alt="World Information App Screenshot"
              />
              <div className={projectsStyles.card__textContainer}>
                <h3 className={projectsStyles.card__title}>
                  World Information App
                </h3>
                <div className={projectsStyles.card__categoryContainer}>
                  <h4 className={projectsStyles.card__category}>React</h4>
                  <h4 className={projectsStyles.card__category}>CSS</h4>
                </div>
                <p className={projectsStyles.card__desc}>
                  A React app built using the REST Countries API where users can
                  see all the countries from the API on the homepage, search for
                  a country using the input field, click on a country to see
                  more detailed information on a separate page and filter
                  countries by region. Axios and the Fetch API were used to
                  fetch and display the data from the API, React Router was used
                  to navigate between pages and the theme toggle switcher was
                  built using Styled Components.
                </p>
                <div className={projectsStyles.card__linkContainer}>
                  <h4 className={projectsStyles.card__linkText}>
                    <Link
                      className={projectsStyles.card__link}
                      to="https://github.com/mo-rob-1/world-info-app"
                    >
                      View Code
                    </Link>
                  </h4>
                  <h4 className={projectsStyles.card__linkText}>
                    <Link
                      className={projectsStyles.card__link}
                      to="https://world-info-app-mocodes.netlify.app/"
                    >
                      View Project
                    </Link>
                  </h4>
                </div>
              </div>
            </div>
          </Fade>

          <Fade>
            <div className={projectsStyles.card}>
              <Img
                className={projectsStyles.card__img}
                fluid={data.weatherappScreenshot.childImageSharp.fluid}
                alt="Weather App Screenshot"
              />
              <div className={projectsStyles.card__textContainer}>
                <h3 className={projectsStyles.card__title}>Weather App</h3>
                <div className={projectsStyles.card__categoryContainer}>
                  <h4 className={projectsStyles.card__category}>React</h4>
                  <h4 className={projectsStyles.card__category}>CSS</h4>
                </div>
                <p className={projectsStyles.card__desc}>
                  This app uses the OpenWeatherMap API to display the current
                  weather forecast and details for any searched location. The
                  Fetch API along with the useEffect and useState hooks were
                  used to help with this implementation whilst props were used
                  to pass data between components. The background image updates
                  to the current weather of the location and validation was
                  added to the input field to ensure it is required on submit
                  and that a valid location is entered.
                </p>
                <div className={projectsStyles.card__linkContainer}>
                  <h4 className={projectsStyles.card__linkText}>
                    <Link
                      className={projectsStyles.card__link}
                      to="https://github.com/mo-rob-1/weather-app"
                    >
                      View Code
                    </Link>
                  </h4>
                  <h4 className={projectsStyles.card__linkText}>
                    <Link
                      className={projectsStyles.card__link}
                      to="https://weather-app-mocodes.netlify.app/"
                    >
                      View Project
                    </Link>
                  </h4>
                </div>
              </div>
            </div>
          </Fade>

          <Fade>
            <div
              className={`${projectsStyles.card} ${projectsStyles.card__marginTop}`}
            >
              <Img
                className={projectsStyles.card__img}
                fluid={data.fyloScreenshot.childImageSharp.fluid}
                alt="Fylo Landing Page Screenshot"
              />
              <div className={projectsStyles.card__textContainer}>
                <h3 className={projectsStyles.card__title}>
                  Fylo Landing Page
                </h3>
                <div className={projectsStyles.card__categoryContainer}>
                  <h4 className={projectsStyles.card__category}>HTML</h4>
                  <h4 className={projectsStyles.card__category}>CSS/SCSS</h4>
                </div>
                <p className={projectsStyles.card__desc}>
                  A Frontend Mentor challenge where I built a responsive landing
                  page. I was provided with a project brief and a mockup of how
                  the page should look. To create the project, I used HTML &
                  SCSS, Parcel to bundle the code, and the 7-1 folder structure
                  method to organize my Sass files.
                </p>
                <div className={projectsStyles.card__linkContainer}>
                  <h4 className={projectsStyles.card__linkText}>
                    <Link
                      className={projectsStyles.card__link}
                      to="https://github.com/mo-rob-1/fylo-dark-theme-landing-page"
                    >
                      View Code
                    </Link>
                  </h4>
                  <h4 className={projectsStyles.card__linkText}>
                    <Link
                      className={projectsStyles.card__link}
                      to="https://fylo-dark-theme-landing-page-mrob.netlify.app/"
                    >
                      View Project
                    </Link>
                  </h4>
                </div>
              </div>
            </div>
          </Fade>
        </div>
      </div>
    </section>
  )
}

export default Projects
